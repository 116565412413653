import React from 'react';

const HorizontalRule = () => {
    return (
        <div className='HorizontalRule'>
            <div className='HorizontalRule__line'></div>
        </div>
    );
};

export default HorizontalRule;