import React from 'react';

const Yandex = () => {
    return (
        <html>
            <body>Verification: 2e9e41e634307037</body>
        </html>
    );
};

export default Yandex;